import React, {useEffect, useState} from "react"

import questionImg from "../../images/luca-pay/question-icon.svg";
import charcoalLineImg from "../../images/luca-pay/charcoal-line.svg";
import r1 from "../../images/luca-pay/r1.svg";
import r2 from "../../images/luca-pay/r2.svg";
import r2Mob from "../../images/luca-pay/r2_mob.svg"
import r3 from "../../images/luca-pay/r3.svg";
import r4 from "../../images/luca-pay/r4.svg";
import r4Mob from "../../images/luca-pay/r4_mob.svg"
import r5 from "../../images/luca-pay/r5.svg";
import r5Mob from "../../images/luca-pay/r5_mob.svg"
import Tooltip from "rc-tooltip";
import RightVectorGreenImage from "../../images/new-landing/right-vector-green.svg";
import ReactGA from "react-ga";
const CustomToolTip = props => {
    const tooltipStyle = {
        height: "auto",
        width: props.width,
        textAlign: "center",
        verticalAlign: "middle",
    }
    return (
        <Tooltip
            overlayStyle={tooltipStyle}
            placement="top"
            overlay={props.tooltipText}
            trigger={["hover"]}
            arrowContent={<div className="rc-tooltip-arrow-inner" />}
        >
            {props.children}
        </Tooltip>
    )
}
export default function ExistingUser({toggleConfirmationPopup}) {
    const signUpNowClick = (title, index) => {
        ReactGA.event({
            category: `LUCA Pay_${title} apply now_Green button`,
            action: `LUCA Pay_${title} apply now_Green button Clicked`
        });
        toggleConfirmationPopup(`applynow_arrow_${index}`);
    }
    return (
        <div className="lucapay__requirements lucapay__exisiting__user">
                <div className="header text-neutral-2 font-bold">
                    Are you an existing LUCA Plus subscriber?
                    <div className="hidden sm:flex font-normal justify-content-center text-neutral-3 text-16 leading-6 mt-6">
                        Check out what you need below to apply for LUCA Pay - it only <div className="font-medium ml-1"> takes 2 minutes! <img src={charcoalLineImg} className="mt-1" /></div>
                    </div>
                    <div className="text-neutral-3 font-normal text-16 leading-6 mt-6 block sm:hidden px-12">
                        Check out what you need below to apply for LUCA Pay - it only <span className="font-medium"> takes 2 minutes! </span>
                    </div>
                </div>

            <div className="flex justify-between flex-wrap box-wrap ">
                {requirements.map(({image, title, content, tooltipText, className = "", titleClassName}, index) => (
                    <div className="box" key={index}>
                        <img src={image} className="mx-auto "/>
                        <div className={`flex justify-content-center capitalize font-bold items-start text-18 leading-5 mb-6 ${titleClassName}`}>
                            {title}
                            {tooltipText && <CustomToolTip
                                height="46px"
                                width="160px"
                                tooltipText={
                                    <span>{tooltipText}</span>}
                            >
                                <img
                                    src={questionImg}
                                    className="ml-1"
                                    alt="luca"
                                    data-parallax='{"x" : -50}'
                                />
                            </CustomToolTip>}
                        </div>
                        <div className={`text-neutral-3 text-16 leading-6 content ${className}`}>
                            {content}
                        </div>
                        <a
                            onClick={() => signUpNowClick(title, index + 1)}
                            className="start-now-btn flex justify-content-center mt-4 text-base uppercase focus:outline-none cursor-pointer"
                        >
                            APPLY NOW
                            <img src={RightVectorGreenImage} className="ml-2" alt="right arrow"/>
                        </a>
                    </div>
                ))}
            </div>
        </div>
    )
}
const requirements = [
    {
        image: r2,
        title: "GST registered",
        content: "To ensure you’re receiving ample cash flow to cover larger invoice amounts, you must be registered",
        tooltipText: "If you’re not registered - unfortunately you can not apply for LUCA Pay."
    },
    {
        image: r4,
        titleClassName: "mb-2",
        className: "basiq-text",
        title: <div>Basiq Open Banking <div className="box-sub-header"> powered by NAB </div></div>,
        content: "Link your business bank account upon sign up to ensure you have sufficient funds to cover direct debits",
        tooltipText: "Your bank account name needs to match your ABN",
    },
    {
        image: r5,
        className: "px-2",
        title: "Set up Direct Debit",
        content: "Once you link your business bank account, you can set up your Direct Debit"
    },
]
