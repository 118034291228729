import React, {useEffect, useState} from "react"

import "../../scss/style.scss"

import SEO from "../../components/seo"

import Top from "../../components/luca-pay/top";
import Hero from "../../components/luca-pay/hero"
import Steps from "../../components/luca-pay/steps"
import Requirements from "../../components/luca-pay/requirements"
import ExistingUser from "../../components/luca-pay/exisiting-user"

import Modali, {useModali} from "../../components/modal"

import seoImage from "../../images/luca-pay/seo.jpg"
import {Link} from "gatsby";
import ReactGA from "react-ga";

export default function NewLandingPage() {
    const [confirmationModal, toggleConfirmationModal] = useModali()
    const [existingOrgModal, toggleExistingOrgModal] = useModali({
        title: "Please select one option",
        onHide: toggleConfirmationModal
    })
    const [selectedOption, handleSelectedOption] = useState("");
    const [supplier, setSupplier] = useState(false);
    const [toggle, setToggle] = useState(false)
    const [activePage, setActivePage] = useState("")

    useEffect(() => {
        window.open("https://www.lucapay.com", "_self")
    },[])

    const navItemClick = (navItem) => {
        ReactGA.event({
            category: 'NAV_ITEM',
            action: `Clicked ${navItem} Nav Item of LUCA Pay Page`
        });
    }
    const onChange = () => {
        setSupplier(!supplier);
        ReactGA.event({
            category: `LUCA Pay_${!supplier ? "Supplier" : "Purchaser"}_Toggle button`,
            action: `LUCA Pay_Supplier_Toggle button`
        });
    }

    const yesClickHandler = () => {
        ReactGA.event({
            category: `yes_${activePage}_${!supplier ? "supplier" : "purchaser"}`,
            action: `yes_${activePage}_${!supplier ? "supplier" : "purchaser"}`
        });
        window.open(process.env.GATSBY_APP_URL + "/login?lucapay=true", "_self")
    }


    const noClickhandler = () => {
        ReactGA.event({
            category: `no_${activePage}_${!supplier ? "supplier" : "purchaser"}`,
            action: `no_${activePage}_${!supplier ? "supplier" : "purchaser"}`
        });
        toggleConfirmationModal();
        toggleExistingOrgModal();
    }

    const toggleConfirmationPopup = (page) => {
        setActivePage(page);
        toggleConfirmationModal();
    }
    return (
        <div className="h-full font-robo new-landingpage lucapay">
            <SEO title="LUCA Pay | LUCA+ Australia" description="" keywords="LUCA Pay" image={seoImage}/>
            {/*<Top navItemClick={navItemClick} toggle={toggle} setToggle={setToggle} toggleConfirmationModal={toggleConfirmationModal} />*/}
            {/*<Hero toggleConfirmationPopup={toggleConfirmationPopup} supplier={supplier} />*/}
            {/*<Steps onChange={onChange} supplier={supplier} toggleConfirmationPopup={toggleConfirmationPopup} />*/}
            {/*<Requirements supplier={supplier} toggleConfirmationPopup={toggleConfirmationPopup} />*/}
            {/*<ExistingUser toggleConfirmationPopup={toggleConfirmationPopup} />*/}
            {/*<Footer simple={true} lucapay={true} page="LUCA Pay"/>*/}
            {/*<Modali.Modal {...confirmationModal} className=" error-modal">*/}
            {/*    <div className="pt-12 pop-up">*/}
            {/*        <div className="pop-up-inner mx-auto">*/}
            {/*            <div className=" text-neutral-3 mt-4">*/}
            {/*                Are you an existing LUCA Plus subscriber?*/}
            {/*            </div>*/}
            {/*            <div className="mt-8 mb-20 flex justify-center">*/}
            {/*                <div*/}
            {/*                    onClick={yesClickHandler}*/}
            {/*                    className="cursor-pointer flex items-center"*/}
            {/*                >*/}
            {/*                    <input*/}
            {/*                        className="option cursor-pointer"*/}
            {/*                        type="radio"*/}
            {/*                        value="yes"*/}
            {/*                        name="option"*/}
            {/*                        checked={selectedOption === "yes"}*/}
            {/*                        onChange={() => handleSelectedOption("yes")}*/}
            {/*                    />*/}
            {/*                    <label className="ml-3" htmlFor="option-1">*/}
            {/*                        Yes*/}
            {/*                    </label>*/}
            {/*                </div>*/}
            {/*                <a*/}
            {/*                    className="ml-10 cursor-pointer flex items-center"*/}
            {/*                >*/}
            {/*                    <input*/}
            {/*                        type="radio"*/}
            {/*                        value="no"*/}
            {/*                        name="option"*/}
            {/*                        checked={selectedOption === "no"}*/}
            {/*                        onChange={noClickhandler}*/}
            {/*                    />*/}
            {/*                    <label className="ml-3" htmlFor="option-1">*/}
            {/*                        No*/}
            {/*                    </label>*/}
            {/*                </a>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</Modali.Modal>*/}

            <Modali.Modal {...existingOrgModal} className=" error-modal exist-org-modal" >
                <div className="pt-4">
                    <div className="pop-up-inner mx-auto">
                        <div className=" text-neutral-3 mt-4">

                        </div>
                        <div className="mt-8 mb-12 flex flex-col">
                            <Link
                                to={`/sign-up/`}
                                state={{ isTrial: true, plan: "starter", price: 0, lucapay: true }}
                                className="popup-box cursor-pointer"
                            >
                                <input
                                    className="option cursor-pointer"
                                    type="radio"
                                    value="xero"
                                    name="option"
                                    checked={selectedOption === "xero"}
                                    onClick={() => handleSelectedOption("xero")}
                                />
                                <label className="ml-5 w-10/12" htmlFor="option-1">
                                    Do you use Xero, MYOB or QBO for your
                                    invoicing needs?
                                </label>
                            </Link>
                            <Link
                                to={`/sign-up/`}
                                state={{ isTrial: true, plan: "solo", price: 0, lucapay: true }}
                                className="popup-box cursor-pointer mt-5"
                            >
                                <input
                                    type="radio"
                                    value="lucaplus"
                                    name="option"
                                    checked={selectedOption === "lucaplus"}
                                    onClick={() => handleSelectedOption("lucaplus")}
                                />
                                <label className="w-10/12 ml-5" htmlFor="option-1">
                                    Would you prefer to set up LUCA Plus as
                                    your invoicing solution?
                                </label>
                            </Link>
                        </div>
                    </div>
                </div>
            </Modali.Modal>

        </div>
    )
}
